import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import { ContentHubArticle } from '@propertypal/shared/src/types/strapi/overrides';
import { findRichText, getArticleImage } from '@propertypal/shared/src/utils/content-hub';
import dayjs from 'dayjs';
import Link from 'next/link';
import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';
import Chevron from '../icons/Chevron';
import FontAwesomeIcon from '../icons/FontAwesomeIcon';
import { Box } from '../layout';
import { NewsBox, NewsContent, NewsImage } from './ContentHubArticleBox.style';
import {
  CarouselContent,
  ContentHubCarouselContainer,
  SliderBtn,
  Title,
  ViewMore,
  MobileViewMore,
} from './ContentHubCarousel.style';
import { ContentHubContent } from './ContentHubPageContainer.style';
import 'swiper/css';
import 'swiper/css/scrollbar';

interface ContentHubCarouselProps {
  articles?: ContentHubArticle[] | null;
  title: string;
  viewMore: string;
}

const ContentHubCarousel: React.FC<ContentHubCarouselProps> = ({ articles, title, viewMore }) => {
  const [swiper, setSwiper] = useState<SwiperClass>();
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(articles && articles?.length > 3);

  if (articles && articles.length > 0) {
    return (
      <ContentHubCarouselContainer data-testid="article-carousel">
        <ContentHubContent>
          <Title>{title}</Title>
          <Link href={viewMore} prefetch={false} className="view-more">
            <ViewMore>View more</ViewMore>
          </Link>
        </ContentHubContent>
        <CarouselContent>
          {showLeftArrow && (
            <SliderBtn $direction="left" onClick={() => swiper?.slidePrev()} data-testid="carousel-prev">
              <FontAwesomeIcon icon={faChevronLeft} size={24} />
            </SliderBtn>
          )}

          {showRightArrow && (
            <SliderBtn $direction="right" onClick={() => swiper?.slideNext()} data-testid="carousel-next">
              <FontAwesomeIcon icon={faChevronRight} size={24} />
            </SliderBtn>
          )}

          <Swiper
            onSwiper={setSwiper}
            simulateTouch
            slidesPerView="auto"
            onTransitionEnd={(mSwiper) => {
              setShowLeftArrow(!mSwiper.isBeginning);
              setShowRightArrow(!mSwiper.isEnd);
            }}
          >
            {articles
              .filter((a) => !a?.attributes?.hidden)
              .sort((a, b) => (dayjs(b.attributes.publishedAt).isBefore(dayjs(a.attributes.publishedAt)) ? -1 : 1))
              .slice(0, 6)
              .map((article) => (
                <SwiperSlide key={article.id}>
                  <NewsBox
                    // eslint-disable-next-line max-len
                    href={`/${article.attributes.subCategories?.data[0].attributes.articleCategory}/${article.attributes.subCategories?.data[0].attributes.slug}/${article.attributes.slug}`}
                  >
                    {/* {article.attributes.tag?.data?.attributes && (
                      <ArticleTag
                        data-testid="article-carousel-tag"
                        colour={article.attributes.tag.data?.attributes.textColour}
                        bg={article.attributes.tag.data?.attributes.backgroundColour}
                        mb={10}
                      >
                        {article.attributes.tag.data?.attributes.tag}
                      </ArticleTag>
                    )} */}
                    <NewsImage image={getArticleImage(article)} />

                    <NewsContent>
                      <Box gap={15} display="flex" flexDirection="column">
                        <h3>{article.attributes.title}</h3>

                        <p>{article.attributes.excerpt || findRichText(article.attributes.content)}</p>
                      </Box>

                      <p>
                        Read more <Chevron size={11} />
                      </p>
                    </NewsContent>
                  </NewsBox>
                </SwiperSlide>
              ))}
          </Swiper>
          <MobileViewMore>
            <Link href={viewMore} prefetch={false}>
              <ViewMore>View more</ViewMore>
            </Link>
          </MobileViewMore>
        </CarouselContent>
      </ContentHubCarouselContainer>
    );
  }

  return null;
};

export default ContentHubCarousel;
